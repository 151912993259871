import { apiCallback } from 'api';
import { handlePersonalisationHeader } from 'api/utils/personalisation-header';

const definition = {
  defaultVersion: 1,
  endpoint: 'products',
  query: {
    availableDateTimeRange: '${dateTime}',
    branchId: '${branchId}',
    categoryId: '${categoryId}',
    customersFavouritesOnly: '${favourites}',
    excludeLinesFromTrolley: '${excludeTrolleyLines}',
    filters: '${filters}',
    productCollectionId: '${collectionId}',
    promotionflag: '${promotionflag}',
    searchTerm: '${searchTerm}',
    size: '${size}',
    slotType: '${slotType}',
    sortBy: '${sortBy}',
    start: '${start}',
    trolleyId: '${trolleyId}',
    view: 'SUMMARY',
    showRecommendations: '${showRecommendations}',
    excludeLinesWithConflicts: '${excludeLinesWithConflicts}',
    promotionTypes: '${promotionTypes}',
  },
  headers: { experience: 'forgotten-favourites' },
  service: 'products',
  verbs: ['get'],
};

export default {
  get: apiCallback(definition, 'get', handlePersonalisationHeader),
};
