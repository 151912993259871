import browseWithRecommendations from 'api/definitions/browse/plp';
import {
  FAILED,
  LOADED,
  LOADING,
  RECOMMENDATIONS_PLP_PRODUCTS_FULFILLED,
} from 'redux/modules/search-and-browse/actions/types';
import { getGridItems } from 'redux/modules/search-and-browse/selectors';
import { COMPONENTS_AND_PRODUCTS_SCHEMA, LEGACY_PRODUCTS_SCHEMA } from 'redux/schemas';
import { TRANSFORM_GRID_ITEMS, TRANSFORM_PRODUCTS_INTO_LEGACY_FORMAT } from 'redux/transforms';
import { GET_CMS_PAGE_NOT_FOUND } from 'redux/modules/cms-page/actions/types';
import { setRequestProperty } from 'redux/modules/search-and-browse/actions/utils/set-request-property';
import { getFeatureFlags } from 'utils/feature-flags';
import { GROCERIES } from 'constants/categoryIds';

const notFoundStatuses = [400, 404];

export const fetchComponentsAndProducts =
  ({ request: req, start = 0, successType: productsSuccessType = LOADED, refinement }) =>
  (dispatch, getState) => {
    const state = getState();
    const { searchAndBrowse: { displayedProducts, request: searchAndBrowseRequest } = {} } = state;
    let request = req || searchAndBrowseRequest;

    if (start !== 0) request = setRequestProperty(request, 'start', start);

    // If products have been invalidated load a fresh page,
    // or the previously loaded total, whichever is greater
    if (displayedProducts !== 0) {
      if (getGridItems(state).length < displayedProducts) {
        request = setRequestProperty(
          request,
          'size',
          Math.min(96, Math.max(48, displayedProducts)),
        );
      }
    }

    const { recommendationsWithFallback } = getFeatureFlags();

    const {
      category,
      customerId,
      filterTags,
      size,
      sortBy,
      orderId: trolleyId,
    } = request?.body?.customerSearchRequest?.queryParams || {};

    const isGLP = category === GROCERIES;
    const recommendationsSize = isGLP && start === 0 ? 8 : 0;
    const experience = isGLP ? 'grocery-landing-page' : null;

    return dispatch({
      // TODO: https://www.jlpit.com/jira/browse/WPIP-50711
      //
      // We should create a new LOADED action for PLP with associated:
      //
      // - reducer: handling the merge of product grid and recs into state
      // - schema: handling the Graph response structure
      // - transform: handling the Graph response structure
      //
      // Until then we imperatively handle the success state.
      types: [LOADING, null, FAILED],
      request,
      start,
      isOffer: false,
      apiCall: browseWithRecommendations.post({ experience })({
        category,
        customerId,
        filterTags,
        size,
        start,
        sortBy,
        trolleyId,
        recommendationsSize,
        withFallback: recommendationsWithFallback,
        iHandleStatusCodes: notFoundStatuses,
      }),
      refinement,
    })
      .then((response = {}) => {
        const { data } = response;

        const dispatches = [
          dispatch({
            type: productsSuccessType,
            request,
            start,
            isOffer: false,
            result: {
              ...data.getProductListPage.productGridData,
            },
            schema: COMPONENTS_AND_PRODUCTS_SCHEMA,
            transform: TRANSFORM_GRID_ITEMS,
            refinement,
          }),
        ];

        if (recommendationsSize) {
          dispatches.push(
            dispatch({
              type: RECOMMENDATIONS_PLP_PRODUCTS_FULFILLED,
              result: {
                ...data.getProductListPage.recommendedProducts,
              },
              schema: LEGACY_PRODUCTS_SCHEMA,
              transform: TRANSFORM_PRODUCTS_INTO_LEGACY_FORMAT,
            }),
          );
        }

        return Promise.all(dispatches);
      })
      .catch(error => {
        if (notFoundStatuses.includes(error.status)) {
          return dispatch({ type: GET_CMS_PAGE_NOT_FOUND });
        }

        return dispatch({
          type: FAILED,
          error: 'Get PLP call to graph failed',
        });
      });
  };
